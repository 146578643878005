.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.linear-gradient{
  background: 
  linear-gradient(0deg, white, transparent, transparent 80%),
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  /* linear-gradient(0deg, white, #ffffff10, #ffffff10 97%), */
  linear-gradient(90deg,  #00CEFF, #00D744, #00CEFF00 80%), 
  linear-gradient(96deg, #00D744 , #00D74480 30%), 
  /* linear-gradient(135deg,  #403E3E70,#403E3E80 70%),  */
  /* linear-gradient(310deg, #00000090, #000000  60%), */
  linear-gradient(310deg, #00000090, #000000  60%);
  /* linear-gradient(0deg, white, transparent, transparent 99%); */
}
.linear-gradient{
  background: 
  linear-gradient(0deg, white, transparent, transparent 80%),
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  /* linear-gradient(0deg, white, #ffffff10, #ffffff10 97%), */

  linear-gradient(90deg,  #00CEFF, #00D744, #00CEFF00 80%), 
  linear-gradient(96deg, #00D744 , #00D74480 30%), 
  /* linear-gradient(135deg,  #403E3E70,#403E3E80 70%),  */
  /* linear-gradient(310deg, #00000090, #000000  60%), */

  linear-gradient(310deg, #00000090, #000000  60%);
  /* linear-gradient(0deg, white, transparent, transparent 99%); */

}

.linear-gradient-dialog{
   background-image: 'radial-gradient(at bottom right, #039f10, #008d65, #007593, #005998, #003877, #0f2a65, #141c53, #140f41, #140f41, #121633, #121633 , #121633)',
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  /*linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  linear-gradient(0deg, white, #ffffff40, #ffffff20 97%),
  linear-gradient(to right, #00CEFF70,  #00D74460, #70707080) ;*/
}

.linear-gradient-drawer{
  background:  
  linear-gradient(0deg, white, transparent, transparent 80%),
  /* linear-gradient(0deg, white, #ffffff20, #ffffff05 97%), */
  linear-gradient(0deg, white, #ffffff30, #ffffff10 97%),
  linear-gradient(0deg, white, #ffffff40, #ffffff20 97%),
  linear-gradient(to right, #00CEFF50,  #00D74450, #00D74440, #ffffff10, #ffffff70, white);
}

.linear-gradient-drawer-bottom{
  background:  
  linear-gradient(180deg, #ffffff26 ,#ffffff26  ),
  linear-gradient(120deg, #00CEFF36,  transparent, transparent,transparent  70%),
  linear-gradient(150deg, transparent,  #00D74436, #EBEBEB50, transparent,#ffffff20, transparent  90%),
  linear-gradient(150deg, transparent,#ffffff20 ,transparent,transparent, #00CEFF25 ,  transparent,transparent,transparent 140%),
  linear-gradient(120deg, transparent ,transparent, transparent,  #ffffff20, #00D74436  ,transparent 120%);

}

.linear-gradient-cd-background{
  background-image: linear-gradient(to left top, #039f10, #008d65, #007593, #005998, #003877, #0f2a65, #141c53, #140f41, #140f41, #140f41, #140f41, #140f41);
}

.linear-gradient-cd-drawer{
  background-image: linear-gradient(to left top, #cdeccf, #cdeccf, #c5ecd9, #CDE3EF, #CDE3EF,#CEE1EE,  #CDE3ED, #d0d6e4, #d0d2df, #d0ceda, #D0CEDA, #D0CEDA, #D0CEDA, #D0CEDA, #D0CEDA,  #D0CEDA, #D0CEDA)
}

/*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=fc923c&c2=186a1e&gt=r&gd=dbr
*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {


  0% { transform: rotate(0deg); }


  100% { transform: rotate(360deg); }


}



.circle {
  width: 180px; 
  height: 180px; 
  border-radius: 50%;
  position: relative;
}



.circle-div {
  width: 30px; 
  height: 70px; 
  background-color: #fff; 
  border-radius: 20px;
  position: absolute;
}


.circle-div:nth-child(1) { top: 0; left: 50%; transform: translateX(-50%); transform: rotate(0deg);}
.circle-div:nth-child(2) { top: 15%; left: 85%; transform: translateX(-50%); transform: rotate(45deg);}
.circle-div:nth-child(3) { top: 50%; left: 100%; transform: translateX(-50%); transform: rotate(90deg);}
.circle-div:nth-child(4) { top: 85%; left: 85%; transform: translateX(-50%); transform: rotate(135deg);}
.circle-div:nth-child(5) { top: 100%; left: 50%; transform: translateX(-50%); transform: rotate(180deg);}
.circle-div:nth-child(6) { top: 85%; left: 15%; transform: translateX(-50%); transform: rotate(225deg);}
.circle-div:nth-child(7) { top: 50%; left: 0; transform: translateX(-50%); transform: rotate(270deg);}
.circle-div:nth-child(8) { top: 15%; left: 15%; transform: translateX(-50%); transform: rotate(315deg);}



.circle-div:nth-child(8) {
  background-color: transparent;
  border: 8px solid white;
}

div.spinner {
  position: relative;
  width: 180px;
  height: 180px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;
  /* background: #000; */
  padding: 10px;
  border-radius: 10px;
}

div.spinner div {
  width: 8%;
  height: 16%;
/*   background: #FFF; */
  border: 5px solid #fff;
  position: absolute;
  /* background-color: #000000; */
  left: 49%;
  top: 43%;
  /* opacity: 1; */
  animation: fade 1s ease-in infinite;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -webkit-animation: fade 1s ease-in infinite;
}

@keyframes fade {
  from { background-color: #fff; opacity:1;}
  to { background-color: transparent; opacity: 1;}
}

div.spinner div.bar1 {
  transform:rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
  -webkit-transform:rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}    

div.spinner div.bar2 {
  transform:rotate(45deg) translate(0, -130%); 
  animation-delay: -0.875s;
  -webkit-transform:rotate(45deg) translate(0, -130%); 
  -webkit-animation-delay: -0.875s;
}

div.spinner div.bar3 {
  transform:rotate(90deg) translate(0, -130%); 
  animation-delay: -0.75s;
  -webkit-transform:rotate(90deg) translate(0, -130%); 
  -webkit-animation-delay: -0.75s;
}
div.spinner div.bar4 {
  transform:rotate(135deg) translate(0, -130%); 
  animation-delay: -0.625s;
  -webkit-transform:rotate(135deg) translate(0, -130%); 
  -webkit-animation-delay: -0.625s;
}
div.spinner div.bar5 {
  transform:rotate(180deg) translate(0, -130%); 
  animation-delay: -0.5s;
  -webkit-transform:rotate(180deg) translate(0, -130%); 
  -webkit-animation-delay: -0.5s;
}
div.spinner div.bar6 {
  transform:rotate(225deg) translate(0, -130%); 
  animation-delay: -0.375s;
  -webkit-transform:rotate(225deg) translate(0, -130%); 
  -webkit-animation-delay: -0.375s;
}
div.spinner div.bar7 {
  transform:rotate(270deg) translate(0, -130%); 
  animation-delay: -0.25s;
  -webkit-transform:rotate(270deg) translate(0, -130%); 
  -webkit-animation-delay: -0.25s;
}
div.spinner div.bar8 {
  transform:rotate(315deg) translate(0, -130%); 
  animation-delay: -0.125s;
  -webkit-transform:rotate(315deg) translate(0, -130%); 
  -webkit-animation-delay: -0.125s;
}


/* div.spinner div.bar1 {
  transform:rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
  -webkit-transform:rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}    

div.spinner div.bar2 {
  transform:rotate(30deg) translate(0, -130%); 
  animation-delay: -0.9167s;
  -webkit-transform:rotate(30deg) translate(0, -130%); 
  -webkit-animation-delay: -0.9167s;
}

div.spinner div.bar3 {
  transform:rotate(60deg) translate(0, -130%); 
  animation-delay: -0.833s;
  -webkit-transform:rotate(60deg) translate(0, -130%); 
  -webkit-animation-delay: -0.833s;
}
div.spinner div.bar4 {
  transform:rotate(90deg) translate(0, -130%); 
  animation-delay: -0.7497s;
  -webkit-transform:rotate(90deg) translate(0, -130%); 
  -webkit-animation-delay: -0.7497s;
}
div.spinner div.bar5 {
  transform:rotate(120deg) translate(0, -130%); 
  animation-delay: -0.667s;
  -webkit-transform:rotate(120deg) translate(0, -130%); 
  -webkit-animation-delay: -0.667s;
}
div.spinner div.bar6 {
  transform:rotate(150deg) translate(0, -130%); 
  animation-delay: -0.5837s;
  -webkit-transform:rotate(150deg) translate(0, -130%); 
  -webkit-animation-delay: -0.5837s;
}
div.spinner div.bar7 {
  transform:rotate(180deg) translate(0, -130%); 
  animation-delay: -0.5s;
  -webkit-transform:rotate(180deg) translate(0, -130%); 
  -webkit-animation-delay: -0.5s;
}
div.spinner div.bar8 {
  transform:rotate(210deg) translate(0, -130%); 
  animation-delay: -0.4167s;
  -webkit-transform:rotate(210deg) translate(0, -130%); 
  -webkit-animation-delay: -0.4167s;
}
div.spinner div.bar9 {
  transform:rotate(240deg) translate(0, -130%); 
  animation-delay: -0.333s;
  -webkit-transform:rotate(240deg) translate(0, -130%); 
  -webkit-animation-delay: -0.333s;
}
div.spinner div.bar10 {
  transform:rotate(270deg) translate(0, -130%); 
  animation-delay: -0.2497s;
  -webkit-transform:rotate(270deg) translate(0, -130%); 
  -webkit-animation-delay: -0.2497s;
}
div.spinner div.bar11 {
  transform:rotate(300deg) translate(0, -130%); 
  animation-delay: -0.167s;
  -webkit-transform:rotate(300deg) translate(0, -130%); 
  -webkit-animation-delay: -0.167s;
}
div.spinner div.bar12 {
  transform:rotate(330deg) translate(0, -130%); 
  animation-delay: -0.0833s;
  -webkit-transform:rotate(330deg) translate(0, -130%); 
  -webkit-animation-delay: -0.0833s;
} */








