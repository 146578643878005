
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

/*
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(0, 28, 35);
} 

::-webkit-scrollbar-thumb {
  background-color: #f26641;
  outline: 1px solid slategrey;
  border-radius: 20px;
}
::-webkit-scrollbar-button {
  background-color: rgb(0, 28, 35);

  color: white;
}
::-webkit-scrollbar-button:single-button {
  background-color: rgb(0, 28, 35);

  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  color: white;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='120' fill='rgb(256,256,256)'><polygon points='50 0, 0 50, 100 50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  color: white;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='120' fill='rgb(256,256,256)'><polygon points='0 0, 50 50, 100 0'/></svg>");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Flex classes */

.flex {
  display: flex;
}
.f-wrap {
  flex-wrap: wrap;
}
.row {
  flex-direction:row;
}
.col {
  flex-direction: column;
}
.j-center {
  justify-content: center;
}
.j-between {
  justify-content: space-between;
}
.j-start {
   justify-content: flext-start;
}
.j-end {
   justify-content: flex-end;
}
.a-center {
  align-items: center;
}
.a-start {
  align-items: flex-start;
}
.a-end {
  align-items: flex-end;
}
